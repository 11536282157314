<template>
	<article class="calendar-border">
		<template v-if="showHeaderImage">
			<ImageHeader></ImageHeader>
		</template>
		<NavBar />
		<component v-bind:is="mainView" :key="viewKey" />
		<PopupManager />
	</article>
</template>

<script>
import {parseHashQuery, parseQuery} from '../../url';
import restClient from '../../rest';
import {Config} from '../storage';
import CalendarApp from '../app';

const comments = {};

export default {
	name: "Main",
	data(){
		return {
			mainView: 'EventList',
			showHeaderImage: true,
			viewKey: 1,
		};
	},
	async created(){
		if(Config.has('headerImage') && !Config.get('headerImage')){
			this.showHeaderImage = false;
		}
		this.$root.$on('changeMainView', this.changeMainView);
		const query = {...parseHashQuery(), ...parseQuery()};
		
		document.addEventListener('resize', () => {
			this.$root.$emit('resize');
		});
		
		if('event' in query){
			const eventDate = await restClient.ajax({
				endpoint: 'eventDate/' + query['event'],
				method: 'GET',
			});
			this.$root.$emit('removePopup', {id: 'eventPopup'});
			
			this.$root.$emit('addPopup', {
				id: 'eventPopup',
				component: 'EventPopup',
				params: {
					eventDate: eventDate,
				},
			});
		}
		if('eventKind' in query){
			CalendarApp.loadEventKindFilterById(query.eventKind);
		}
	},
	mounted(){
		comments.on = document.createComment('');
		comments.off = document.createComment('');
		
		this.$root.$on('popupCountChanged', (popupCount) => {
			if(popupCount){
				comments.on.textContent = 'googleon: all';
				comments.off.textContent = 'googleoff: all';
			}else{
				comments.on.textContent = '';
				comments.off.textContent = '';
			}
		});
		
		const article = document.querySelector('.calendar-border');
		article.prepend(comments.off);
		article.insertBefore(comments.on, document.querySelector('.event-popups'));
	},
	methods: {
		changeMainView(evt){
			if(evt.view && this.mainView !== evt.view){
				this.mainView = evt.view;
				this.viewKey++;
			}
		},
	},
}
</script>

<style scoped>

</style>