import {ICalendar} from 'datebook';

/**
 * @param {{
 * 		start: Date,
 * 		end: Date,
 * 		title: string,
 * 		description: string,
 * 		location: string,
 * }} data
 */
async function downloadIcsFile(data){
	const ics = new ICalendar(data);
	ics.download();
}

export {downloadIcsFile};