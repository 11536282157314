<template>
	<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px"
		viewBox="0 0 2500 2500" style="enable-background:new 0 0 2500 2500;" xml:space="preserve">
		<path class="st0" d="M1250,0C559.6,0,0,559.6,0,1250s559.6,1250,1250,1250s1250-559.6,1250-1250S1940.4,0,1250,0z M1887.2,923.2
	c0.7,14.1,1,28.3,1,42.5c0,434.5-330.8,935.6-935.6,935.6c-185.7,0-358.5-54.5-504.1-147.8c25.7,3.1,52,4.7,78.5,4.7
	c154,0,295.8-52.6,408.4-140.8c-143.9-2.7-265.3-97.8-307.1-228.3c20.1,3.8,40.7,5.8,61.9,5.8c30,0,59-4,86.6-11.5
	C626.3,1353.1,513,1220.3,513,1060.9v-4.2c45.7,25.4,96.7,39.5,149,41.1c-88.2-59-146.3-159.6-146.3-273.7
	c0-60.3,16.2-116.8,44.5-165.3c162.2,198.9,404.5,329.8,677.8,343.6c-5.6-24.1-8.5-49.2-8.5-75c0-181.7,147.2-328.8,328.8-328.8
	c94.6,0,180.1,39.9,240,103.8c73.7-14.5,144.3-41.5,208.9-79.8c-24.6,76.7-76.6,141.2-144.6,181.9c66.6-7.9,129.9-25.6,188.9-51.8
	c0,0,0,0,0,0l0,0c0,0,0,0,0,0C2007.3,818.9,1951.6,876.6,1887.2,923.2z" />
</svg>
</template>

<script>
export default {
	name: "Twitter",
	data(){
		return {};
	},
}
</script>

<style scoped>
.st0 {
	fill: #FFFFFF;
}
</style>