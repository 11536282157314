<template>
	<section v-if="headerImg" class="headerImage">
		<img :src="headerImg"/>
	</section>
</template>

<script>
import {Config} from '../storage';

export default {
	name: "ImageHeader",
	data: function(){
		return {
			headerImg: '',
		};
	},
	created(){
		if(Config.has('headerImage')){
			this.headerImg = Config.get('headerImage')
		}
	}
}
</script>

<style scoped>

</style>