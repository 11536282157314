<template>
	<transition name="fade-in">
		<div class="event-popup-border event-popup-print" @click="close()">
			<div class="event-popup-inner" @click="preventClose($event)">
				<div class="event-popup-spacer">
					<div class="event-popup-header">
						<div>
							<h3>{{ this.title }}</h3>
						</div>
						<div>
							<div @click="close" class="closeButton clickable event-popup-print-ignore" role="button"
								 title="schließen">
								<Icon name="close"/>
							</div>
						</div>
					</div>
					<div class="event-popup-slider">
						<div class="event-popup-slider-inner">
							<div v-if="images.length > 1" class="event-popup-slider-nav">
								<div class="clickable" @click="$refs.mainSlide.goToPrev()">
									<Icon name="chevron_left"/>
								</div>
							</div>
							<agile
								ref="mainSlide"
								:dots="images.length > 1"
								:as-nav-for="navForMain"
								:nav-buttons="false"
								:options="agileOptions"
							>
								<template v-for="image in images">
									<div :key="image.id"
										 class="slide event-slide-image-container event-popup-print-pictures">
										<img :src="image.url" class="event-slide-image" :alt="image.name"
											 draggable="false"/>
										<div v-if="eventDate.canceled" class="event-slide-image-canceled">
											<h2>Abgesagt</h2>
										</div>
									</div>
								</template>
							</agile>
							<div v-if="images.length > 1" class="event-popup-slider-nav">
								<div class="clickable" @click="$refs.mainSlide.goToNext()">
									<Icon name="chevron_right"/>
								</div>
							</div>
						</div>
						<template v-if="images.length > 1">
							<div class="navSlider">
								<agile
									ref="navSlide"
									:dots="false"
									:nav-buttons="false"
									:as-nav-for="navForNav"
									:slides-to-show="3"
									:centerMode="true"
								>
									<template v-for="(image, index) in images">
										<div :key="image.id" class="slide" @click="$refs.navSlide.goTo(index)">
											<img
												:src="image.url"
												:alt="image.name"
												style="max-width: 150px; max-height: 150px;"
												draggable="false"
											/>
											<div v-if="eventDate.canceled" class="event-slide-image-canceled">
												<h2>Abgesagt</h2>
											</div>
										</div>
									</template>
								
								</agile>
							</div>
						</template>
					</div>
					<div
						class="event-popup-description"
						v-html="description"
						:class="{hasContent: description}"
					>
					</div>
					<div class="event-popup-details">
						<div class="event-popup-details-event">
							<div>
								<h4>Veranstaltung</h4>
								<div class="event-popup-icon-list">
									<div v-if="eventDate.event.sustainability">
										<img :src="icon_url + '/eco_black' + icon_size + 'icon_white.png'"
											 alt="Nachhaltig"/>
									</div>
									<div v-if="eventDate.event.accessible">
										<img :src="icon_url + '/accessible_black' + icon_size + 'icon_white.png'"
											 alt="Barrierefrei"/>
									</div>
									<div v-if="eventDate.event.family_friendly">
										<img :src="icon_url + '/family_restroom' + icon_size + 'icon_white.png'"
											 alt="Familienfreundlich"/>
									</div>
									<div v-if="eventDate.event.with_cost">
										<i class="material-icons">euro_symbol</i>
									</div>
								</div>
							</div>
							<div v-if="downloads.length > 0" class="event-popup-downloads">
								<h4>Downloads</h4>
								<div v-for="download in downloads" :key="download.id">
									<div class="clickable" @click="downloadFile(download.url, download.name)">
										<Icon name="download"/>
										<span>{{ download.name }}</span>
									</div>
								</div>
							</div>
							<div v-if="title">
								<Icon name="event" :iconStyle="{'margin-left': '-3px', 'top': '-2px',}"/>
								<span v-if="title">
								{{ title }}
							</span>
							</div>
							<div v-if="date">
								<Icon name="schedule" :iconStyle="{'margin-left': '-3px'}"/>
								<span>
									{{ date }}
								</span>
							</div>
							<div v-if="eventDate.location.name">
								<Icon name="place" :iconStyle="{'margin-left': '-5px'}"/>
								<span>{{ eventDate.location.name }}<span
									v-if="eventDate.street"> | {{ eventDate.street }}<span
									v-if="eventDate.street_nr"> {{ eventDate.street_nr }}</span>
								</span><span
									v-if="eventDate.address_supplement"
								> | {{ eventDate.address_supplement }}</span></span>
							</div>
							<div
								class="event-popup-print-ignore"
								title="Termin als Kalendereintrag speichern"
							>
								<Icon name="download" :iconStyle="{'margin-left': '-5px', 'top': '-2px',}"/>
								<span class="clickable" @click="exportDate" role="button">
									 Termin als Kalendereintrag speichern
								</span>
							</div>
							<div v-if="eventDate.event.price">
								<Icon name="euro" :iconStyle="{'margin-left': '-3px', 'top': '-2px',}"/>
								<span
									v-html="eventDate.event.price"
								>
								</span>
							</div>
							<div v-if="eventDate.event.ticket_presale">
								<Icon name="book_online" :iconStyle="{'margin-left': '-3px', 'top': '-px'}"/>
								<a :href="eventDate.event.ticket_presale" target="_blank">Zum Vorverkauf</a>
							</div>
						</div>
						<div class="event-popup-details-organisator">
							<h4>Veranstalter</h4>
							<div v-if="eventDate.event.organisation">
								<Icon name="person" :iconStyle="{'margin-left': '-3px', 'top': '-2px',}"/>
								<span>
								{{ eventDate.event.organisation }}
							</span>
							</div>
							<div v-if="eventDate.event.mail">
								<Icon name="alternate_email" :iconStyle="{'margin-left': '-3px', 'top': '-2px',}"/>
								<a :href="'mailto:'+ eventDate.event.mail">
								<span>
									{{ eventDate.event.mail }}
								</span>
								</a>
							</div>
							<div v-if="eventDate.event.phone">
								<Icon name="call" :iconStyle="{'margin-left': '-3px', 'top': '-2px',}"/>
								<a :href="'tel:'+ eventDate.event.phone">
								<span>
									{{ eventDate.event.phone }}
								</span>
								</a>
							</div>
							<div v-if="eventDate.event.web">
								<Icon name="language" :iconStyle="{'margin-left': '-3px', 'top': '-2px',}"/>
								<a :href="web" target="_blank">
								<span>
									{{ eventDate.event.web }}
								</span>
								</a>
							</div>
							<div v-if="hasSocialMedia" style="display: flex; flex-direction: row; gap: 16px;">
								<a :href="eventDate.event.facebook" style="padding-left:0;" target="_blank">
									<Facebook v-if="eventDate.event.facebook" style="height: 24px;"/>
								</a>
								<a :href="eventDate.event.instagram" style="padding-left:0;" target="_blank">
									<Instagram v-if="eventDate.event.instagram" style="height: 24px;"/>
								</a>
								<a :href="eventDate.event.twitter" style="padding-left:0;" target="_blank">
									<Twitter v-if="eventDate.event.twitter" style="height: 24px;"/>
								</a>
							</div>
						</div>
					</div>
					<div
						v-if="gps.lat && gps.lng"
						class="event-popup-map event-popup-print-ignore"
					>
						<ejs-maps
							:zoomSettings="zoomSettings"
							:centerPosition="{latitude: gps.lat, longitude: gps.lng}"
						>
							<e-layers>
								<e-layer layerType="OSM">
									<e-markerSettings>
										<e-markerSetting
											visible=true
											:dataSource="[
												{
													latitude: gps.lat,
													longitude: gps.lng,
													name: eventDate.location.name
												}
											]"
											shape="Balloon"
											:height="markerSize"
											:width="markerSize"
										>
										
										</e-markerSetting>
									</e-markerSettings>
								</e-Layer>
							</e-layers>
						</ejs-maps>
					</div>
					
					<div class="event-popup-footer">
						<div @click="print" class="clickable event-popup-print-ignore" role="button"
							 title="schließen"
							 style="margin-right:20px">
							<span>Ansicht drucken</span>
						</div>
						<div @click="close" class="clickable event-popup-print-ignore" role="button"
							 title="schließen">
							<span>zurück zur Übersicht</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {setHashQuery, setQuery} from '../../url';
import {VueAgile} from 'vue-agile';
import {formatDate, formatTime, getWeekdayShort} from '../../helper';
import {MapsPlugin, Marker, Zoom} from '@syncfusion/ej2-vue-maps';
import {downloadIcsFile} from '../../ics';
import {stripTags} from '../../html';
import downloadImage from '../../image';
import {Config, Cache} from '../storage';

export default {
	name: "EventPopup",
	components: {
		agile: VueAgile
	},
	data(){
		return {
			zoomSettings: {
				enable: true,
				//shouldZoomInitially: true,
				enablePanning: true,
				mouseWheelZoom: false,
				maxZoom: 19,
				zoomFactor: 15,
			},
			markerSize: 25,
			navForNav: [],
			navForMain: [],
			agileOptions: {
				responsive: [
					{
						breakpoint: 992,
						settings: {
							dots: false,
						}
					},
				],
			},
			icon_size: '/2x/',
			icon_url: 'https://events.hassberge.de/storage/icons',
			orgTitle: null,
		};
	},
	provide: {
		maps: [Zoom, Marker],
	},
	props: {
		eventDate: {
			type: Object,
		}
	},
	created(){
		console.log(this);
		setTimeout(() => {
			this.navForNav = [this.$refs.mainSlide];
			this.navForMain = [this.$refs.navSlide];
		});
		if(Config.get('changeTitle', false)){
			let titleEl = document.querySelector('title');
			if(titleEl){
				this.orgTitle = titleEl.innerText;
				titleEl.innerText = `${this.title} | ${this.orgTitle}`;
			}
		}
		
	},
	beforeDestroy(){
		if(Config.get('changeTitle', false)){
			let titleEl = document.querySelector('title');
			if(titleEl){
				titleEl.innerText = this.orgTitle;
			}
		}
	},
	computed: {
		gps(){
			if(this.eventDate.gps.lat && this.eventDate.gps.lng){
				return this.eventDate.gps;
			}
			return this.eventDate.location.gps;
			
		},
		hasSocialMedia(){
			return this.eventDate.event.facebook || this.eventDate.event.instagram || this.eventDate.event.twitter;
		},
		description(){
			if(this.eventDate.description){
				return this.eventDate.description;
			}else{
				return Config.get('descriptionFallback');
			}
		},
		title(){
			if(this.eventDate.title){
				return this.eventDate.event.name + ' - ' + this.eventDate.title;
			}
			
			return this.eventDate.event.name;
		},
		images(){
			const images = [];
			
			this.eventDate.event.media.forEach(media => {
				if(media.kind === 'image'){
					if(!media.url.includes('events.hassberge.de')){
						media.url = 'https://events.hassberge.de/' + media.url;
					}
					images.push(media);
				}
			});
			return images;
		},
		downloads(){
			const downloads = [];
			this.eventDate.event.media.forEach(media => {
				if(media.kind !== 'image'){
					if(!media.url.includes('events.hassberge.de')){
						media.url = 'https://events.hassberge.de/' + media.url;
					}
					downloads.push(media);
				}
			});
			
			return downloads;
		},
		date(){
			let result = '';
			if(this.eventDate.date_start){
				result = `${getWeekdayShort(this.eventDate.date_start)} ${formatDate(this.eventDate.date_start)}`;
			}
			
			if(this.eventDate.date_end && this.eventDate.date_end !== this.eventDate.date_start){
				result += ` | bis ${getWeekdayShort(this.eventDate.date_end)}  ${formatDate(this.eventDate.date_end)}`;
			}
			
			if(this.eventDate.time_start){
				result += ` | ${!this.eventDate.time_end? 'ab ' : ''}${formatTime(this.eventDate.time_start)}`;
				if(this.eventDate.time_end){
					result += ` - ${formatTime(this.eventDate.time_end)}`;
				}
			}
			
			return result;
		},
		web(){
			if(this.eventDate.event.web.includes('http')){
				return this.eventDate.event.web;
			}else{
				return 'http://' + this.eventDate.event.web;
			}
		}
	},
	methods: {
		close(){
			this.$root.$emit('removePopup', {id: 'eventPopup'});
			setQuery(Cache.get('oldQuery', {}), ['event']);
		},
		print(){
			let htmlContent = document.getElementsByClassName('event-popup-print')[0].innerHTML;
			let stylesheets = document.querySelectorAll('link[rel="stylesheet"], style');
			
			let htmlSheets = '';
			stylesheets.forEach(singleSheet => {
				htmlSheets += singleSheet.outerHTML;
			});
			
			const printWindow = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
			printWindow.document.write(`<!DOCTYPE html>
				<html>
					<head>
						${htmlSheets}
					</head>
					<body>
						${htmlContent}
					</body>
				</html>`);
			
			let printPictures = printWindow.document.getElementsByClassName('event-slide-image');
			for(let i = 0; i < printPictures.length; i++){
				if(printPictures[i].parentElement.parentElement.classList.contains("agile__slides--regular")){
					printPictures[i].style.width = "400px";
				}else{
					printPictures[i].style.display = "none";
				}
			}
			
			let printIgnoreElements = printWindow.document.getElementsByClassName('event-popup-print-ignore');
			for(let i = 0; i < printIgnoreElements.length; i++){
				printIgnoreElements[i].style.display = "none";
			}
			printWindow.document.close();
			printWindow.focus();
			setTimeout(() => {
				printWindow.print();
				printWindow.close();
			}, 1000);
		},
		preventClose(evt){
			evt.stopPropagation();
		},
		exportDate(){
			let start = this.eventDate.date_start;
			if(this.eventDate.time_start){
				start += 'T';
				start += this.eventDate.time_start;
			}else{
				start += 'T00:00:00';
			}
			
			let location = this.eventDate.location.name;
			
			if(this.eventDate.location.street){
				location += ' ' + this.eventDate.location.street;
			}
			
			const data = {
				start: new Date(start),
				title: this.title,
				description: this.eventDate.description? stripTags(this.eventDate.description) : '',
				location: location,
			};
			
			if(this.eventDate.date_end){
				let end = this.eventDate.date_end;
				if(this.eventDate.time_end){
					end += 'T';
					end += this.eventDate.time_end;
				}else{
					end += 'T23:59:59';
				}
				data.end = new Date(end);
			}else{
				if(this.eventDate.time_end){
					data.end = new Date(`${this.eventDate.date_start}T${this.eventDate.time_end}`);
				}else{
					data.end = new Date(`${this.eventDate.date_start}T23:59:59`);
				}
			}
			downloadIcsFile(data);
		},
		downloadFile: downloadImage,
	},
}
</script>

<style scoped lang="scss">
.social_media_icon {
	height: 54px;
}
</style>