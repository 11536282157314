<template>
	<div>
		<ejs-maps v-if="loaded"
			:zoomSettings="zoomSettings"
			v-on:markerClick="onLocationClicked($event)"
		>
			<e-layers>
				<e-layer layerType="OSM">
					<e-markerSettings>
						<template v-for="loc in locations">
							<e-markerSetting :key="loc.id"
								visible=true
								:dataSource="[
												{
													latitude: loc.gps.lat,
													longitude: loc.gps.lng,
													name: loc.name
												}
											]"
								shape="InvertedTriangle"
								height="25"
								width="25"
							>
							</e-markerSetting>
						</template>
					</e-markerSettings>
				</e-Layer>
			</e-layers>
		</ejs-maps>
	</div>
</template>

<script>
import {Cache, Filter} from '../storage';
import restClient from '../../rest';
import {copyHashMap} from '../../helper';
import {MapsPlugin, Marker, Zoom} from '@syncfusion/ej2-vue-maps';
import {VueAgile} from 'vue-agile';
import {setQuery} from '../../url';

export default {
	name: "MapFilter",
	components: {
		agile: VueAgile
		
	},
	provide: {
		maps: [Zoom, Marker],
	},
	data(){
		return {
			locations: [],
			loaded: false,
			zoomSettings: {
				enable: true,
				shouldZoomInitially: true,
				enablePanning: true,
				maxZoom: 15,
			}
		};
	},
	async created(){
		setQuery({}, ['eventListPage']);
		if(!Cache.has('mapLocations')){
			await this.loadData();
		}else{
			this.locations = copyHashMap(Cache.get('mapLocations'));
		}
		this.loaded = true;
	},
	methods: {
		async loadData(){
			const result = await restClient.ajax({
				endpoint: 'location',
				method: 'GET',
				data: {
					pageSize: 1000,
				}
			});
			
			this.locations = [];
			result.data.forEach(loc => {
				if(!loc.parent && loc.gps && loc.gps.lat && loc.gps.lng){
					this.locations.push(loc);
				}
			});
			
			Cache.set('mapLocations', copyHashMap(this.locations));
		},
		onLocationClicked(evt){
			const loc = this.getLocationByName(evt.data.name);
			if(loc){
				Filter.set('location', ['event_dates.location', 'in', [loc.id]]);
				Filter.set('locationDesc', copyHashMap([loc]));
				
				this.$root.$emit('filterChanged', Filter);
				
				this.$root.$emit('changeMainView', {
					view: 'EventList',
					nav: {
						filter: false,
						map: false,
						help: false,
					},
				});
			}
		},
		getLocationByName(name){
			for(let loc of this.locations){
				if(loc.name === name){
					return loc;
				}
			}
			return null;
		},
	},
}
</script>

<style scoped>

</style>