/**
 * @return {string}
 */
function generateUniqueId(){
	return Math.random().toString(32).slice(2);
}

/**
 * @param {string} sqlDate
 */
function formatDate(sqlDate){
	return sqlDate.split('-').reverse().join('.');
}

/**
 * @param {string} sqlTime
 */
function formatTime(sqlTime){
	if(!sqlTime){
		return '';
	}
	return sqlTime.substr(0, sqlTime.lastIndexOf(':'));
}

const shortWeekDays = [
	'SO',
	'MO',
	'DI',
	'MI',
	'DO',
	'FR',
	'SA',
];

/**
 * @param {string} date
 */
function getWeekdayShort(date){
	return shortWeekDays[(new Date(date)).getDay()];
}

function toSqlDate(date){
	return date.split('.').reverse().join('-');
}

/**
 * @return {string}
 */
function getCurrentDate(){
	const x = new Date();
	const year = x.getFullYear().toString();
	
	let month = (x.getMonth() + 1).toString();
	if(month.length === 1){
		month = '0' + month;
	}
	
	let day = x.getDate().toString();
	if(day.length === 1){
		day = '0' + day;
	}
	return `${year}-${month}-${day}`;
}

/**
 * @param {{}} obj
 * @return {{}}
 */
function copyHashMap(obj){
	return JSON.parse(JSON.stringify(obj));
}

/**
 * @param {String} string
 * @return {String}
 */
function escapeRegExp(string){
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export {
	generateUniqueId,
	formatDate,
	toSqlDate,
	formatTime,
	getCurrentDate,
	copyHashMap,
	escapeRegExp,
	getWeekdayShort,
};