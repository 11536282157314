<template>
	<div class="event-filter" v-touch:swipe.bottom="close">
		<div class="event-filter-nav">
			<div>
				<h3>
					<span class="no-select">&nbsp;</span>
					Filter
					<span class="no-select">&nbsp;&nbsp;&nbsp;</span>
				</h3>
			</div>
			<div class="button-group">
				<div @click="setFilter" class="clickable confirm-button" role="button" title="übernehmen">
					<Icon name="check"/>
				</div>
				<div @click="close" class="closeButton clickable" role="button" title="schließen">
					<Icon name="close"/>
				</div>
			</div>
		</div>
		<div>
			<div class="event-filter-date">
				<input type="date" v-model="dateFrom"/>
				<span class="event-filter-date-seperator">&mdash;</span>
				<input type="date" v-model="dateTill"/>
			</div>
			<div class="event-filter-location">
				<LocationSelector
					ref="locationSelector"
					v-on:locationChanged="onLocationChanged"
					v-on:locationsLoaded="onLocationsLoaded"
					v-bind:selectedLocations="location"
				/>
			</div>
			<div class="event-filter-online-location">
				<div class="event-filter-select online" role="tab">
					<span>Onlineveranstaltung</span>
					<label class="switch">
						<input
							type="checkbox"
							v-model="onlineEventChecked"
							value="1"
							v-on:change="onOnlineEventChange()"
						/>
						<span class="slider round"
						></span>
					
					</label>
				</div>
			</div>
			<div class="event-filter-eventKind">
				<EventKindSelector
					ref="eventKindSelector"
					v-on:eventKindChangedChanged="onEventKindChangedChanged"
					v-on:eventKindsLoaded="onEventKindsLoaded"
					v-bind:selectedEventKinds="eventKind"
				/>
			</div>
		</div>
		<div class="event-filter-selection">
			<template v-if="somethingSelected">
				<hr/>
				<h3>
					<span class="no-select">&nbsp;</span>
					Ihre Auswahl
					<span class="no-select">&nbsp;&nbsp;&nbsp;</span>
				</h3>
				<div class="event-filter-selection-list"><!-- dates -->
					<div class="date-view" v-if="dateFrom">
						{{ formatDate(dateFrom) }}
						<span class="clickable buttonFix" @click="dateFrom=''" role="button" title="entfernen">
							<Icon name="close" styleClass="remove"/>
						</span>
					</div>
					<div class="date-view-seperator" v-if="dateFrom && dateTill">
						<span>&mdash;</span>
					</div>
					<div class="date-view" v-if="dateTill">
						{{ formatDate(dateTill) }}
						<span class="clickable buttonFix" @click="dateTill=''" role="button" title="entfernen">
							<Icon name="close" styleClass="remove"/>
						</span>
					</div>
				</div>
				<div class="event-filter-selection-list"><!-- locations -->
					<template v-for="loc in locations">
						<div class="location-view " v-if="loc.selected" :key="loc.id">
							{{ loc.name }}
							<span class="clickable buttonFix" @click="removeLocation(loc)" role="button"
								title="entfernen">
								<Icon name="close" styleClass="remove"/>
							</span>
						</div>
					</template>
				</div>
				<div class="event-filter-selection-list"><!-- eventkinds -->
					<template v-for="eventKind in eventKinds">
						<div class="location-view" v-if="eventKind.selected" :key="eventKind.id">
							{{ eventKind.name }}
							<span class="clickable buttonFix" @click="removeEventKind(eventKind)">
								<Icon name="close" styleClass="remove"/>
							</span>
						</div>
					</template>
				</div>
			</template>
		</div>
		<div class="event-filter-buttons">
			<div @click="setFilter" class="clickable" role="button">
				Filter anwenden
			</div>
			
			<div @click="resetFilter" class="clickable" role="button">
				Filter zurücksetzen
			</div>
		</div>
	</div>
</template>

<script>
import {Filter} from '../storage';
import {getCurrentDate} from '../../helper';
import {formatDate} from '../../helper';

export default {
	name: "SearchFilter",
	data(){
		return {
			dateFrom: '',
			dateTill: '',
			location: [],
			eventKind: [],
			eventKinds: [],
			locations: [],
			onlineEventChecked: false,
			onlineLocationId: 237,
		};
	},
	created(){
		this.setDataFromFilter();
	},
	computed: {
		somethingSelected(){
			if(
				this.dateFrom
				|| this.dateTill
				|| this.location.length > 0
				|| this.eventKind.length > 0
			){
				return true;
			}
			return false;
		},
	},
	methods: {
		setDataFromFilter(){
			if(Filter.has('dateFrom')){
				this.dateFrom = Filter.get('dateFrom')[2];
			}else{
				this.dateFrom = '';
			}
			if(Filter.has('dateTill')){
				this.dateTill = Filter.get('dateTill')[2];
			}else{
				this.dateTill = '';
			}
			if(Filter.has('location')){
				this.location = Filter.get('location')[2];
			}else{
				this.location = [];
			}
			
			if(Filter.has('eventKind')){
				this.eventKind = Filter.get('eventKind')[2];
			}else{
				this.eventKind = [];
			}
		},
		close(){
			this.$root.$emit('changeMainView', {
				view: 'EventList',
				nav: {
					filter: false,
					map: false,
					help: false,
				}
			});
		},
		setFilter(){
			if(this.dateFrom){
				Filter.set('dateFrom', ['event_dates.date', '>=', this.dateFrom]);
			}else if(Filter.has('dateFrom')){
				Filter.delete('dateFrom');
			}
			if(this.dateTill){
				Filter.set('dateTill', ['event_dates.date', '<=', this.dateTill]);
			}else if(Filter.has('dateTill')){
				Filter.delete('dateTill');
			}
			
			this.location = [];
			const locObjects = [];
			this.$refs.locationSelector.locations.forEach(loc => {
				if(loc.selected){
					this.location.push(loc.id);
					locObjects.push(loc);
				}
			});
			
			if(this.location.length){
				Filter.set('location', ['event_dates.location', 'in', this.location]);
				Filter.set('locationDesc', JSON.parse(JSON.stringify(locObjects)));
			}else{
				if(Filter.has('location')){
					Filter.delete('location');
					Filter.delete('locationDesc');
				}
			}
			
			this.eventKind = [];
			const eventKindObjects = [];
			
			this.$refs.eventKindSelector.eventKinds.forEach(eventKind => {
				if(eventKind.selected){
					this.eventKind.push(eventKind.id);
					eventKindObjects.push(eventKind);
				}
			});
			
			if(this.eventKind.length){
				Filter.set('eventKind', ['events.kind', 'in', this.eventKind]);
				Filter.set('eventKindDesc', JSON.parse(JSON.stringify(eventKindObjects)));
			}else{
				if(Filter.has('eventKind')){
					Filter.delete('eventKind');
					Filter.delete('eventKindDesc');
				}
			}
			
			this.$root.$emit('filterChanged', Filter);
			this.close();
		},
		resetFilter(){
			Filter.clear();
			Filter.set('dateFrom', ['event_dates.date', '>=', getCurrentDate()]);
			this.$refs.locationSelector.resetLocations();
			this.$refs.eventKindSelector.resetEventKinds();
			this.$root.$emit('filterChanged', Filter);
			this.onlineEventChecked = false;
			this.setDataFromFilter();
		},
		formatDate: formatDate,
		onEventKindChangedChanged(eventKind){
			for(let i in this.eventKinds){
				if(eventKind.id === this.eventKinds[i].id){
					this.eventKinds.splice(i, 1);
				}
			}
			if(eventKind.selected){
				this.eventKinds.push(eventKind);
			}
			
			this.eventKinds = this.eventKinds.sort((a, b) => a.name > b.name);
		},
		onEventKindsLoaded(evt){
			evt.eventKinds.forEach(loc => {
				if(loc.selected){
					this.eventKinds.push(loc);
				}
			});
			this.eventKinds = this.eventKinds.sort((a, b) => a.name > b.name);
		},
		removeEventKind(eventKind){
			this.$refs.eventKindSelector.toggleEventKind(eventKind);
		},
		onLocationChanged(location){
			for(let i in this.locations){
				if(location.id === this.locations[i].id){
					this.locations.splice(i, 1);
				}
			}
			if(location.selected){
				this.locations.push(location);
				if(location.id === this.onlineLocationId){
					this.onlineEventChecked = true;
				}
			}else{
				if(location.id === this.onlineLocationId){
				}
				this.onlineEventChecked = false;
			}
			
			
			this.locations = this.locations.sort((a, b) => a.name > b.name);
		},
		onLocationsLoaded(evt){
			evt.locations.forEach(loc => {
				if(loc.selected){
					this.locations.push(loc);
				}
			});
			this.locations = this.locations.sort((a, b) => a.name > b.name);
		},
		removeLocation(location){
			this.$refs.locationSelector.toggleLocation(location);
		},
		onOnlineEventChange(){
			this.$refs.locationSelector.locations.forEach(loc => {
				if(loc.id === this.onlineLocationId){
					this.$refs.locationSelector.toggleLocation(loc);
				}
			});
		},
	},
}
</script>

<style scoped>

</style>