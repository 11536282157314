<template>
	<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px"
		viewBox="0 0 19.2 19.2" style="enable-background:new 0 0 19.2 19.2;" xml:space="preserve">
		<path class="st0" d="M13.5,6.7c-0.2-0.4-0.5-0.8-0.9-0.9c-0.3-0.1-0.6-0.2-0.9-0.2c-0.5,0-0.7,0-2,0s-1.5,0-2,0
	c-0.3,0-0.6,0.1-0.9,0.2C6.2,5.9,5.9,6.2,5.7,6.7C5.6,6.9,5.5,7.3,5.5,7.6c0,0.5,0,0.7,0,2s0,1.5,0,2c0,0.3,0.1,0.6,0.2,0.9
	c0.2,0.4,0.5,0.8,0.9,0.9c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,0.7,0,2,0s1.5,0,2,0c0.3,0,0.6-0.1,0.9-0.2c0.4-0.2,0.8-0.5,0.9-0.9
	c0.1-0.3,0.2-0.6,0.2-0.9c0-0.5,0-0.7,0-2s0-1.5,0-2C13.7,7.3,13.6,6.9,13.5,6.7z M9.6,12.2C8.2,12.2,7,11,7,9.6S8.2,7,9.6,7
	s2.6,1.1,2.6,2.6c0,0,0,0,0,0C12.2,11,11,12.2,9.6,12.2z M12.3,7.5c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6
	S12.6,7.5,12.3,7.5L12.3,7.5z" />
		<circle class="st0" cx="9.6" cy="9.6" r="1.7" />
		<path class="st0" d="M9.6,0C4.3,0,0,4.3,0,9.6s4.3,9.6,9.6,9.6s9.6-4.3,9.6-9.6S14.9,0,9.6,0C9.6,0,9.6,0,9.6,0z M14.6,11.7
	c0,0.4-0.1,0.8-0.2,1.2c-0.3,0.7-0.8,1.2-1.5,1.5c-0.4,0.1-0.8,0.2-1.2,0.2c-0.5,0-0.7,0-2.1,0s-1.5,0-2.1,0c-0.4,0-0.8-0.1-1.2-0.2
	c-0.7-0.3-1.2-0.8-1.5-1.5c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.5,0-0.7,0-2.1s0-1.5,0-2.1c0-0.4,0.1-0.8,0.2-1.2c0.3-0.7,0.8-1.2,1.5-1.5
	c0.4-0.1,0.8-0.2,1.2-0.2c0.5,0,0.7,0,2.1,0s1.5,0,2.1,0c0.4,0,0.8,0.1,1.2,0.2c0.7,0.3,1.2,0.8,1.5,1.5c0.1,0.4,0.2,0.8,0.2,1.2
	c0,0.5,0,0.7,0,2.1S14.6,11.1,14.6,11.7z" />
</svg>
</template>

<script>
export default {
	name: "Instagram",
	data(){
		return {};
	},
}
</script>

<style scoped>
.st0 {
	fill: #FFFFFF;
}
</style>