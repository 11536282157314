import {Config} from './frontend2/storage';

/**
 * @return {{}}
 */
function parseHashQuery(){
	const hash = window.location.hash.replace('#!', '');
	const queryParts = hash.split('&');
	const query = {};
	queryParts.forEach(queryPart => {
		if(queryPart.includes('=')){
			const parts = queryPart.split('=');
			query[parts[0]] = parts[1]? parts[1] : true;
		}
	});
	return query;
}

/**
 * @param {{}} parts
 * @param {string[]} excludedKeys
 */
function mergeHashQuery(parts, excludedKeys = []){
	const query = parseHashQuery();
	for(let i in parts){
		query[i] = parts[i];
	}
	excludedKeys.forEach(key => {
		if(typeof query[key] !== 'undefined'){
			delete query[key];
		}
	});
	return query;
}

/**
 * @param {{}} query
 * @param {string[]} excludeKeys
 */
function setHashQuery(query, excludeKeys = []){
	query = mergeHashQuery(query, excludeKeys);
	let queryString = '';
	for(let key in query){
		queryString += `${key}=${query[key].toString()}&`;
	}
	if(queryString.length > 0){
		queryString = '#!' + queryString.substr(0, queryString.length - 1);
	}else{
		queryString = '#!';
	}
	
	window.history.pushState({
		jit: Math.random() + new Date().getTime(),
	}, '', queryString);
	
	
	changeCanonicalUrl();
}

function changeCanonicalUrl(){
	if(Config.has('changeCanonical') && Config.get('changeCanonical')){
		let el = document.querySelector('link[rel="canonical"]');
		if(!el){
			el = document.createElement('link');
			el.rel = 'canonical';
			document.querySelector('head').appendChild(el);
		}
		
		el.href = window.location.toString();
	}
}

//function getQuery(){
//	let res = window.location.search;
//	if(res.substr(0, 1) === '?'){
//		res = res.substr(1);
//	}
//
//	return res;
//}
//
//function parseQuery(){
//	const stringQuery = getQuery();
//	const parts = stringQuery.split('&');
//	const query = {};
//	parts.forEach(part => {
//		if(part.includes('=')){
//			const parts = part.split('=');
//			query[parts[0]] = parts[1]? parts[1] : true;
//		}
//	});
//
//	return query;
//}
//
//function mergeQuery(parts, excludedKeys = []){
//	const query = parseQuery();
//	for(let i in parts){
//		query[i] = parts[i];
//	}
//	excludedKeys.forEach(key => {
//		if(typeof query[key] !== 'undefined'){
//			delete query[key];
//		}
//	});
//	return query;
//}
//
//function setQuery(query, excludeKeys = []){
//	query = mergeQuery(query, excludeKeys);
//	let queryString = '';
//	for(let key in query){
//		queryString += `${key}=${query[key].toString()}&`;
//	}
//	if(queryString.length > 0){
//		queryString = '?' + queryString.substr(0, queryString.length - 1);
//	}else{
//		queryString = '?';
//	}
//
//	if(window.location.hash){
//		queryString += window.location.hash;
//	}
//
//	window.history.pushState({
//		jit: Math.random() + new Date().getTime(),
//	}, '', queryString);
//}

const parseQuery = parseHashQuery;

const setQuery = setHashQuery;

export {
	parseHashQuery,
	setHashQuery,
	parseQuery,
	setQuery,
	changeCanonicalUrl,
}