<template>
	<section class="event-popups">
		<template v-for="popup in this.popups">
			<div :class="`event-popup ${popup.cssClass}`" :key="popup.uId" :style="popup.style"
				@wheel="(event) => event.stopPropagation()">
				<component v-bind:is="popup.component" v-bind="popup.params"></component>
			</div>
		</template>
	</section>
</template>

<script>

import {generateUniqueId} from '../../helper';

export default {
	name: "PopupManager",
	data(){
		return {
			/** @var {{id: string, uId: string component: string, params: Object}[]} */
			popups: [],
		};
	},
	mounted(){
		this.$root.$on('addPopup', (evt) => {
			document.querySelector('body').classList.add('lraApp-noscroll');
			this.addPopup(evt);
		});
		
		this.$root.$on('removePopup', (evt) => {
			document.querySelector('body').classList.remove('lraApp-noscroll');
			this.removePopup(evt);
		});
		
		this.$root.$on('resize', () => {
			this.calcWidth();
		});
	},
	methods: {
		/**
		 * @param {{id: string, uId: string? component: string, params: Object}} popupData
		 */
		addPopup(popupData){
			if(!popupData.uId){
				popupData.uId = generateUniqueId();
			}
			if(!popupData.style){
				popupData.style = {};
			}
			if(!popupData.cssClass){
				popupData.cssClass = '';
			}
			this.popups = [...this.popups, popupData];
			this.$root.$emit('popupCountChanged', this.popupCount());
			this.calcWidth();
		},
		/**
		 * @param {{id:string, wait: ?int}} evt
		 */
		removePopup(evt){
			if(evt.wait){
				setTimeout(() => {
					this.remove(evt.id);
				}, evt.wait);
			}else{
				this.remove(evt.id)
			}
		},
		remove(id){
			for(let i in this.popups){
				if(this.popups[i].id === id){
					this.popups.splice(i, 1);
					this.$root.$emit('popupCountChanged', this.popupCount());
					break;
				}
			}
		},
		calcWidth(){
			const maxWidth = getComputedStyle(document.querySelector('.lraApp .nav-bar')).width;
			
			this.popups.forEach(p => {
				p.style.maxWidth = maxWidth;
			});
		},
		popupCount(){
			return this.popups.length;
		},
	},
}
</script>

<style scoped>

</style>