<template>
	<i
		v-bind:style="iconStyle"
		v-bind:title="title"
		v-bind:class="`${styleClass} material-icons`"
		v-bind:role="isButton? 'button' : ''"
	>
		{{ name }}
	</i>
</template>

<script>
export default {
	name: "Icon",
	props: {
		name: String,
		iconStyle: {
			type: Object,
			default: () => new Object,
		},
		title: {
			type: String,
			default: '',
		},
		styleClass: {
			type: String,
			default: '',
		},
		isButton: {
			type: Boolean,
			default: false,
		}
	},
}
</script>

<style scoped>

</style>