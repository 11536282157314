/**
 * @param {string} filename
 * @param {Blob} file
 *
 * @return {Boolean}
 */
function downloadFile(filename, file){
	const urlCreator = window.Url || window.webkitURL;
	const link = document.createElement('a');
	
	link.download = filename;
	link.href = urlCreator.createObjectURL(file);
	
	link.click();
	
	urlCreator.revokeObjectURL(link.href);
	
	return true;
}


export {downloadFile};