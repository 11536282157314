<template>
	<div>
		<section class="event-list">
			<FilterBar />
			<template v-for="eventDate in events">
				<EventCard :key="eventDate.id" :eventDate="eventDate" />
			</template>
			<!-- kleiner hack um letzes element im grid zu haben -->
			<div class="event-card" style="height: 0;"></div>
			<div class="event-card" style="height: 0;"></div>
			<div class="event-card" style="height: 0;"></div>
			<div class="event-card" style="height: 0;"></div>
			<!-- hack ende -->
		
		</section>
		<infinite-loading
			:identifier="infinitId"
			v-on:infinite="loadEvents"
			spinner="spiral"
		>
			<div slot="no-more">
				<div class="no-more-results">Keine weiteren Ergebnisse</div>
			</div>
		
		</infinite-loading>
		<template v-if="withBackToTop && initialLoadDone && events.length">
			<ToTop />
		</template>
	</div>
</template>

<script>
import {Filter, Config, Cache} from '../storage';
import restClient from '../../rest';
import InfiniteLoading from 'vue-infinite-loading';
import {copyHashMap} from '../../helper';
import {setQuery, parseQuery} from '../../url';

export default {
	name: "EventList",
	components: {
		InfiniteLoading,
	},
	data(){
		return {
			events: [],
			page: 1,
			searchDone: false,
			infinitId: +new Date(),
			resultCount: 0,
			pageSize: 20,
			withBackToTop: true,
			initialLoadDone: false,
		};
	},
	created(){
		this.$root.$on('filterChanged', this.resetSearch);
		
		if(Config.has('pageSize') && Config.get('pageSize')){
			this.pageSize = Config.get('pageSize');
		}
		if(Config.has('withBackToTop')){
			this.withBackToTop = !!Config.get('withBackToTop');
		}
		
		this.$root.$on('searchDone', (evt) => {
			if(evt.page === 1){
				this.$nextTick(() => {
					const borderEl = document.querySelector('.calendar-border');
					if(borderEl){
						borderEl.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
						});
					}
				});
			}
		});
		
		const query = parseQuery();
		if('eventListPage' in query){
			setQuery({eventListPage: this.page});
		}
	},
	beforeDestroy(){
		this.$root.$off('filterChanged', this.resetSearch);
	},
	methods: {
		loadEvents: async function($state){
			const query = parseQuery();
			if('event' in query){
				const old = Cache.get('oldQuery', {});
				old.eventListPage = this.page;
				Cache.set('oldQuery', old);
			}else{
				setQuery({eventListPage: this.page});
			}
			const allowedFilters = ['dateFrom', 'dateTill', 'location', 'eventKind'];
			let filter = [];
			const keys = Filter.keys();
			keys.forEach(key => {
				if(allowedFilters.includes(key)){
					filter.push(Filter.get(key));
				}
			});
			
			if(Filter.has('searchTerm')){
				const searchTerm = Filter.get('searchTerm');
				filter.push(['searchTerm', 'like', searchTerm]);
			}
			
			const result = await restClient.ajax({
				endpoint: 'eventDate',
				method: 'GET',
				data: {
					page: this.page,
					filter: filter,
					pageSize: this.pageSize,
				}
			});
			
			
			this.events = [...this.events, ...result.data];
			$state.loaded();
			
			if(result.meta.current_page === result.meta.last_page || result.data.length === 0){
				this.searchDone = true;
				$state.complete();
			}
			
			this.resultCount = result.meta.total;
			Cache.set('searchResults', copyHashMap(this.events));
			this.$root.$emit('searchDone', {eventCount: result.meta.total, page: this.page});
			this.page++;
			this.initialLoadDone = true;
		},
		resetSearch(){
			this.page = 1;
			this.searchDone = false;
			this.events = [];
			this.initialLoadDone = false;
			this.infinitId += 1;
		},
	},
}
</script>

<style scoped>

</style>