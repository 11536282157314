<template>
	<div class="to-top" role="button">
		<div @click="toTop" class="clickable">
			<Icon name="keyboard_arrow_up"/>
		</div>
	</div>
</template>

<script>
export default {
	name: "ToTop",
	methods: {
		toTop(){
			const el = document.querySelector('.lraApp');
			if(el){
				el.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				});
			}
		},
	},
}
</script>

<style scoped>

</style>