<template>
	<div class="event-filter-eventkind">
		<template v-if="!open">
			<div class="event-filter-select" style="width:190px;" @click="toggleOpen" role="tab">
				<span>
					Veranstaltungsart <b v-if="selectedCount">({{ selectedCount }})</b>
				</span>
				<span class="clickable">
					<Icon name=" expand_more"/>
				</span>
			</div>
		</template>
		<template v-else>
			<div class="event-filter-select open" style="width:190px;" @click="toggleOpen" role="tab">
				<span>
					Veranstaltungsart <b v-if="selectedCount">({{ selectedCount }})</b>
				</span>
				<span class="clickable">
					<Icon name=" expand_less"/>
				</span>
			</div>
			<div class="event-filter-open-select">
				<template v-for="eventKind in eventKinds">
					<div
						class="filter-eventkind-entry clickable"
						v-bind:class="{checked: eventKind.selected}"
						:key="eventKind.id"
						@click="toggleEventKind(eventKind)"
						:title="eventKind.name"
						role="checkbox"
						:aria-checked="eventKind.selected? 'true' : 'false'"
					>
						{{ eventKind.name }}
					</div>
				</template>
			</div>
		</template>
	</div>
</template>

<script>
import {Cache, Filter} from '../storage';
import restClient from '../../rest';

export default {
	name: "EventKindSelector",
	data(){
		return {
			eventKinds: [],
			open: false,
		};
	},
	props: {
		selectedEventKinds: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		selectedCount(){
			return this.eventKinds.filter(e => e.selected).length;
		},
	},
	async created(){
		if(true || !Cache.has('eventKinds')){
			await this.loadEventKinds();
		}else{
			this.eventKinds = JSON.parse(JSON.stringify(Cache.get('eventKinds')));
			this.eventKinds.forEach(e => e.selected = false);
		}
		if(Filter.has('eventKind')){
			const cachedEventKinds = Filter.get('eventKind')[2];
			this.eventKinds.forEach(eventkind => {
				eventkind.selected = cachedEventKinds.indexOf(eventkind.id) > -1;
			});
		}
		this.$emit('eventKindsLoaded', {eventKinds: this.eventKinds});
	},
	methods: {
		resetEventKinds(){
			this.eventKinds.forEach(eventKind => {
				eventKind.selected = false;
			});
		},
		async loadEventKinds(){
			const result = await restClient.ajax({
				endpoint: 'eventkind',
				method: 'GET',
				data: {
					pageSize: 1000,
				}
			});
			if(result && result.data){
				Cache.set('eventKinds', JSON.parse(JSON.stringify(result.data)));
				result.data.forEach(e => {
					e.selected = false;
					if(this.selectedEventKinds.indexOf(e.id) > -1){
						e.selected = true;
					}
				});
				this.eventKinds = result.data;
			}
		},
		toggleOpen(){
			this.open = !this.open;
		},
		toggleEventKind(eventKind){
			eventKind.selected = !eventKind.selected;
			this.$emit('eventKindChangedChanged', eventKind);
		},
	},
	
}
</script>

<style scoped>

</style>