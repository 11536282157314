<template>
	<div class="filter-bar">
		<template v-if="dateView">
			<div class="filter-element clickable" @click="openFilter">
				<span>{{ dateView }}</span>
			</div>
		</template>
		<template v-if="searchTerm">
			<div class="filter-element">
				<span>{{ searchTerm }}</span>
				<span
					class="clickable remove-entry"
					role="button"
					title="entfernen"
					@click="removeSearchTerm"
				>
					<Icon name="close"/>
				</span>
			</div>
		</template>
		<template v-if="locations.length">
			<template v-if="locations.length > 3">
				<div class="filter-element clickable" @click="openFilter">
					Orte ({{ locations.length }})
				</div>
			</template>
			<template v-else v-for="location in locations">
				<div class="filter-element" :key="location.id">
					<span>{{ location.name }}</span>
					<span
						class="clickable remove-entry"
						role="button"
						title="entfernen"
						@click="removeLocation(location)"
					>
						<Icon name="close"/>
					</span>
				</div>
			</template>
		</template>
		<template v-if="eventKinds.length">
			<template v-if="eventKinds.length > 3">
				<div class="filter-element clickable" @click="openFilter">
					Veranstaltungsarten ({{ eventKinds.length }})
				</div>
			</template>
			<template v-else v-for="eventKind in eventKinds">
				<div class="filter-element" :key="eventKind.id">
					<span>{{ eventKind.name }}</span>
					<span
						class="clickable remove-entry"
						role="button"
						title="entfernen"
						@click="removeEventKind(eventKind)"
					>
						<Icon name="close"/>
					</span>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import {Filter, setUrlFromFilter} from '../storage';
import {formatDate} from '../../helper';
import {setQuery, parseQuery} from '../../url';

export default {
	name: "FilterBar",
	data(){
		return {
			dateFrom: null,
			dateTill: null,
			searchTerm: null,
			locations: [],
			eventKinds: [],
		};
	},
	created(){
		setQuery({}, ['eventListPage']);
		this.$root.$on('filterChanged', () => {
			this.getDataFromFilter();
		});
		
		this.getDataFromFilter();
	},
	computed: {
		dateView(){
			if(this.dateFrom && this.dateTill){
				return `${formatDate(this.dateFrom)} - ${formatDate(this.dateTill)}`;
			}
			
			if(this.dateFrom){
				return `Ab: ${formatDate(this.dateFrom)}`;
			}
			
			if(this.dateTill){
				return `Bis: ${formatDate(this.dateTill)}`;
			}
			return false;
		},
	},
	methods: {
		getDataFromFilter(){
			const dateFrom = Filter.get('dateFrom', null);
			if(dateFrom){
				this.dateFrom = dateFrom[2];
			}else{
				this.dateFrom = null;
			}
			const dateTill = Filter.get('dateTill', null);
			if(dateTill){
				this.dateTill = dateTill[2];
			}else{
				this.dateTill = null;
			}
			
			const searchTerm = Filter.get('searchTerm', null);
			if(searchTerm){
				this.searchTerm = searchTerm;
			}else{
				this.searchTerm = null;
			}
			this.locations = Filter.get('locationDesc', []);
			this.eventKinds = Filter.get('eventKindDesc', []);
		},
		openFilter(){
			this.$root.$emit('changeMainView', {view: 'SearchFilter'});
		},
		removeLocation(location){
			const locations = Filter.get('location');
			locations[2] = locations[2].filter(id => id !== location.id);
			
			if(locations[2].length){
				Filter.set('location', locations);
				
				const locationDesc = Filter.get('locationDesc', [])
					.filter(obj => obj.id !== location.id);
				
				Filter.set('locationDesc', locationDesc);
			}else{
				Filter.delete('location');
				Filter.delete('locationDesc');
			}
			
			setUrlFromFilter();
			this.$root.$emit('filterChanged', Filter);
		},
		removeEventKind(eventKind){
			const eventKinds = Filter.get('eventKind');
			eventKinds[2] = eventKinds[2].filter(id => id !== eventKind.id);
			const query = parseQuery();
			
			if('eventKind' in query && parseInt(query.eventKind) === eventKind.id){
				setQuery(query, ['eventKind']);
			}
			if(eventKinds[2].length){
				Filter.set('eventKind', eventKinds);
				const eventKindDesc = Filter.get('eventKindDesc')
					.filter(obj => obj.id !== eventKind.id);
				
				Filter.set('eventKindDesc', eventKindDesc);
			}else{
				Filter.delete('eventKind');
				Filter.delete('eventKindDesc');
			}
			
			setUrlFromFilter();
			this.$root.$emit('filterChanged', Filter);
		},
		removeSearchTerm(){
			Filter.delete('searchTerm');
			
			this.$root.$emit('filterChanged', Filter);
		},
	},
}
</script>

<style scoped>

</style>