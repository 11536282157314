import {downloadFile} from './download';

export default async function downloadImage(url, name){
	const request = {
		method: 'GET',
		redirect: 'follow',
		mode: 'cors',
		headers: {},
	};
	
	const res = await fetch(url, request);
	const blob = await res.blob();
	
	downloadFile(name, blob);
};