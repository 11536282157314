<template>
	<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px"
		viewBox="0 0 1024 1024" style="enable-background:new 0 0 1024 1024;" xml:space="preserve">
		<path class="st0" d="M512,0C229.2,0,0,229.2,0,512c0,255.6,187.2,467.4,432,505.8V660H302V512h130V399.2
	C432,270.9,508.4,200,625.4,200c56,0,114.6,10,114.6,10v126h-64.6c-63.6,0-83.4,39.5-83.4,80v96h142l-22.7,148H592v357.8
	c244.8-38.4,432-250.2,432-505.8C1024,229.2,794.8,0,512,0z" />
</svg>
</template>

<script>
export default {
	name: "Facebook",
	data(){
		return {};
	},
}
</script>

<style scoped>
.st0 {
	fill: #FFFFFF;
}
</style>