<template>
	<section
		class="help"
		:class="{'help-open': open,'help-close': !open}"
		v-touch:swipe.bottom="close"
	>
		<div class="help-nav">
			<div @click="close" class="clickable closeButton" role="button" title="schließen">
				<Icon name="close"/>
			</div>
		</div>
		<div class="help-listing">
			<div class="help-listing-element">
				<div class="help-listing-icon">
					<Icon name="search"/>
				</div>
				<div class="help-listing-text">
					<span>Veranstaltungstitel suchen</span>
				</div>
			</div>
			<div class="help-listing-element">
				<div class="help-listing-icon">
					<Icon name="filter_alt"/>
				</div>
				<div class="help-listing-text">
					<span>Filter</span>
				</div>
			</div>
			<div class="help-listing-element">
				<div class="help-listing-hits">
					Treffer: X
				</div>
				<div class="help-listing-text">
					<span>Anzahl vorhandener Veranstaltungen entsprechend der Suche/Filter</span>
				</div>
			</div>
			<div class="help-listing-element">
				<div class="help-listing-icon">
					<Icon name="view_list"/>
				</div>
				<div class="help-listing-text">
					<span>Kachelansicht</span>
				</div>
			</div>
			<div class="help-listing-element">
				<div class="help-listing-icon">
					<Icon name="location_on"/>
				</div>
				<div class="help-listing-text">
					<span>Kartenansicht</span>
				</div>
			</div>
			
			
			<div class="help-listing-element">
				<div class="help-listing-icon no-background">
					<img :src="icon_url + '/eco_black' + icon_size + 'icon_white.png'" alt="Nachhaltig"/>
				</div>
				<div class="help-listing-text">
					<span>Nachhaltig</span>
				</div>
			</div>
			<div class="help-listing-element">
				<div class="help-listing-icon no-background">
					<img :src="icon_url + '/accessible_black' + icon_size + 'icon_white.png'" alt="Barrierefrei"/>
				</div>
				<div class="help-listing-text">
					<span>Barrierefrei</span>
				</div>
			</div>
			<div class="help-listing-element">
				<div class="help-listing-icon no-background">
					<img :src="icon_url + '/family_restroom' + icon_size + 'icon_white.png'" alt="Familienfreundlich"/>
				</div>
				<div class="help-listing-text">
					<span>Familienfreundlich</span>
				</div>
			</div>
			<div class="help-listing-element">
				<div class="help-listing-icon no-background">
					<i class="material-icons">euro_symbol</i>
				</div>
				<div class="help-listing-text">
					<span>Kostenpflichtig</span>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "Help",
	data(){
		return {
			open: true,
			width: '0px',
			icon_size: '/2x/',
			icon_url: 'https://events.hassberge.de/storage/icons',
		}
	},
	created(){
		this.$root.$on('closeHelp', this.close);
	},
	methods: {
		close(){
			this.open = false;
			this.$root.$emit('removePopup', {id: 'help', wait: 500});
		},
	}
}
</script>

<style scoped>

</style>