import restClient from '../rest';
import Vue from '../../../node_modules/vue/dist/vue.min.js';
import {Config} from './storage';
import VueAgile from 'vue-agile';
import {MapsPlugin} from '@syncfusion/ej2-vue-maps';
import Vue2TouchEvents from 'vue2-touch-events';
import {Filter} from './storage';
import {parseHashQuery, changeCanonicalUrl, parseQuery} from '../url';

Vue.use(VueAgile);
Vue.use(MapsPlugin);
Vue.use(Vue2TouchEvents);


const CalendarApp = {
	renderer: null,
	client: restClient,
	filter: Filter,
	/**
	 * @param {{
	 *     element: string,
	 *     token: string,
	 *     headerImage: ?string,
	 *     pageSize: ?int,
	 *     withBackToTop: ?boolean,
	 *     unsetZIndex: ?boolean,
	 * }} config
	 */
	init: function(config){
		for(let i in config){
			if(config.hasOwnProperty(i)){
				Config.set(i, config[i]);
			}
		}
		
		
		if(!Config.has('element')){
			throw 'Missing target Element in config';
		}
		this.client.setTokenName('calenderToken');
		const border = document.querySelector(Config.get('element'));
		if(!border){
			throw `Element selector ${Config.get('element')} doesn't exist`;
		}
		
		if(Config.get('fixQuery', false)){
			let newUrl = window.location.toString();
			const query = parseQuery();
			if(window.location.search.match(/event=[\d]+/)){
				if(!('event' in query)){
					newUrl = newUrl.replace(/event=[\d]+/, '');
					let eventQuery = window.location.search.match(/event=([\d]+)/);
					if(typeof eventQuery[1] !== 'undefined'){
						newUrl += (!newUrl.includes('#!')? '#!' : '&') + 'event=' + eventQuery[1];
					}
				}
			}
			
			if(window.location.search.match(/eventKind=[\d]+/)){
				
				if(!('eventKind' in query)){
					let eventKindQuery = window.location.search.match(/eventKind=([\d]+)/);
					if(typeof eventKindQuery[1] !== 'undefined'){
						newUrl += (!newUrl.includes('#!')? '#!' : '&') + 'eventKind=' + eventKindQuery[1];
					}
				}
				newUrl = newUrl.replace(/(\?*[^(eventKind)]*)(eventKind=\d+&*)/, '$1');
			}
			if(window.location.search.match(/eventListPage=[\d]+/)){
				newUrl = newUrl.replace(/(\?*[^(eventListPage)]*)(eventListPage=\d+&*)/, '$1');
			}
			
			if(newUrl.includes('?#!')){
				newUrl = newUrl.replace('?#!', '#!');
			}
			
			window.history.replaceState(null, '', newUrl);
		}
		
		const inner = document.createElement('div');
		inner.id = 'lraBorder';
		border.appendChild(inner);
		
		if(Config.has('unsetZIndex') && Config.get('unsetZIndex')
		){
			this.fixZIndex(inner);
		}
		
		this.requireComponents();
		
		let component = 'Main';
		if(!Config.has('token')){
			throw 'Missing api token';
			
		}else{
			this.client.setToken(Config.get('token'));
		}
		
		this.renderer = new Vue({
			el: '#lraBorder',
			data: function(){
				return {
					currentMain: component,
				};
			},
			comments: true,
			template: `
				<div class="lraApp">
				<component v-bind:is="currentMain"></component>
				</div>
			`,
		});
		
		this.registerEventListeners();
	}
	,
	requireComponents: function(){
		const requireComponent = require.context(
			'./components',
			false,
			/[A-z]\w+.(vue|js)$/
		);
		
		requireComponent.keys().forEach(fileName => {
			// Get component config
			const componentConfig = requireComponent(fileName)
			
			// Get PascalCase name of component
			const componentName = fileName
				.split('/')
				.pop()
				.replace(/\.\w+$/, '');
			
			Vue.component(componentName, componentConfig.default || componentConfig);
		});
	}
	,
	setMain: function(component){
		this.renderer.currentMain = component;
	}
	,
	/**
	 * @param {HTMLElement} el
	 */
	fixZIndex: function(el){
		while(el.parentElement && el.parentElement !== document.body){
			el.style.zIndex = 'unset';
			el = el.parentElement;
		}
	}
	,
	loadEventKindFilterById: async function(id){
		if(this.filter.has('eventKind') && parseInt(this.filter.get('eventKind')[2][0]) === parseInt(id)){
			return false;
		}
		const result = await this.client.ajax({
			endpoint: `eventkind/${id}`,
			method: 'GET',
		});
		
		if(result){
			changeCanonicalUrl();
			this.filter.set('eventKind', ['events.kind', 'in', [result.id]]);
			this.filter.set('eventKindDesc', [result]);
			
			this.renderer.$root.$emit('filterChanged', this.filter);
			const borderEl = document.querySelector('.calendar-border');
			if(borderEl){
				borderEl.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
		}
		return true;
	}
	,
	registerEventListeners: function(){
		window.addEventListener('hashchange', (evt) => {
			this.onHashChange(evt)
		});
	}
	,
	onHashChange: function(evt){
		const hashQuery = parseHashQuery();
		if('eventKind' in hashQuery){
			this.loadEventKindFilterById(hashQuery.eventKind);
		}
	}
	,
}


export default CalendarApp;