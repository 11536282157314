import {NonPersistentStorage, PersistentStorage} from '../store';
import {getCurrentDate} from '../helper';
import {setHashQuery} from "../url";

const Config = new NonPersistentStorage();

Config.set('descriptionFallback', '<p>Für weitere Informationen wenden Sie sich bitte an den Veranstalter.</p>');

const Filter = new PersistentStorage('calendarFilter', sessionStorage);
if(!Filter.storeWasRegenerated()){
	Filter.set('dateFrom', ['event_dates.date', '>=', getCurrentDate()]);
}

function setUrlFromFilter(){
	const eventKinds = Filter.get('eventKind', null);
	const locations = Filter.get('location', null);
	const dateFrom = Filter.get('dateFrom', null);
	const dateTill = Filter.get('dateTill', null);
	
	const query = {};
	const exclude = [];
	if(eventKinds?.length > 0){
		query.eventKinds = JSON.stringify(eventKinds[2]);
	}else{
		exclude.push('eventKinds');
	}
	
	if(locations?.length){
		query.locations = JSON.stringify(locations[2]);
	}else{
		exclude.push('locations');
	}
	
	if(dateFrom?.length > 0){
		query.from = dateFrom[2];
	}else{
		exclude.push('from');
	}
	
	if(dateTill?.length > 0){
		query.till = dateTill[2];
	}else{
		exclude.push('till');
	}
	setHashQuery(query, exclude);
}

const Cache = new NonPersistentStorage();

export {Config, Filter, Cache, setUrlFromFilter};