/**
 * @param {string} text
 * @return {string}
 */
function stripTags(text){
	if(typeof DOMParser === 'function'){
		return new DOMParser().parseFromString(text, 'text/html').body.textContent;
	}
	return text;
}

/**
 * @param {String} text
 * @param {Number} length
 * @param {boolean} listFix
 */
function trimText(text, length, listFix = false){
	if(listFix){
		text = text.replace(/\n/g, '').replace(/<li>/g, '<li>\n');
	}
	const withoutTags = stripTags(text).trim();
	
	let shortened;
	if(withoutTags.length <= length){
		shortened = withoutTags;
	}else{
		shortened = withoutTags.substr(0, length - 3) + '...';
	}
	
	if(listFix){
		shortened = shortened.replace(/\n/g, '<br/>');
	}
	
	return shortened;
}


export {stripTags, trimText};