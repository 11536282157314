import {NonPersistentStorage, PersistentStorage} from '../store';
import {getCurrentDate} from '../helper';

const Config = new NonPersistentStorage();

Config.set('descriptionFallback', '<p>Für weitere Informationen wenden Sie sich bitte an den Veranstalter.</p>');

const Filter = new PersistentStorage('calendarFilter', sessionStorage);
if(!Filter.storeWasRegenerated()){
	Filter.set('dateFrom', ['event_dates.date', '>=', getCurrentDate()]);
}

const Cache = new NonPersistentStorage();

export {Config, Filter, Cache};