<template>
	<div class="event-filter-location">
		<template v-if="!open">
			<div class="event-filter-select" style="width:120px;" @click="toggleOpen" role="tab">
				<span>
					Orte <b v-if="selectedCount">({{ selectedCount }})</b>
				</span>
				<span class="clickable">
					<Icon name=" expand_more"/>
				</span>
			</div>
		</template>
		<template v-else>
			<div class="event-filter-select open" style="width:120px;" @click="toggleOpen" role="tab">
				<span>
					Orte <b v-if="selectedCount">({{ selectedCount }})</b>
				</span>
				<span class="clickable">
					<Icon name=" expand_less"/>
				</span>
			</div>
			<div class="event-filter-open-select">
				<div>
					<input class="event-filter-location-search" type="text" v-model="searchTerm" placeholder="Suche"
						@keyup="searchLocation"/>
				</div>
				<div class="filter-location-border">
					<template v-if="searchTerm.length >= minSearchTermLength && searchResults.length">
						<template v-for="(location, index) in searchResults">
							<div
								class="filter-location-entry"
								v-bind:class="{checked: location.selected}"
								:key="index"
								@click="toggleLocation(location)"
								role="checkbox"
								:aria-checked="location.selected? 'true' : 'false'"
							>
								{{ location.name }}
							</div>
						</template>
					</template>
					<template v-else>
						<template v-for="(location, index) in mainLocations">
							<div
								class="filter-location-entry"
								v-bind:class="{checked: location.selected}"
								:key="index"
								@click="toggleLocation(location)"
								role="checkbox"
								:aria-checked="location.selected? 'true' : 'false'"
							>
								{{ location.name }}
							</div>
						</template>
					</template>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import {Filter, Cache} from '../storage';
import {escapeRegExp} from '../../helper';
import restClient from '../../rest';

export default {
	name: "LocationSelector",
	data(){
		return {
			locations: [],
			mainLocations: [],
			childLocations: [],
			open: false,
			searchTimeout: null,
			minSearchTermLength: 3,
			searchTerm: '',
			searchResults: [],
			
		};
	},
	props: {
		selectedLocations: {
			type: Array,
			default: () => [],
		}
	},
	computed: {
		selectedCount(){
			return this.locations.filter(loc => loc.selected).length;
		},
	},
	async created(){
		if(true || !Cache.has('locations')){
			await this.loadLocations()
		}else{
			const loc = Cache.get('locations');
			this.locations = JSON.parse(JSON.stringify(loc.locations));
			this.mainLocations = JSON.parse(JSON.stringify(loc.mainLocations));
			this.childLocations = JSON.parse(JSON.stringify(loc.childLocations));
		}
		if(Filter.has('location')){
			const cachedLocations = Filter.get('location')[2];
			this.locations.forEach(loc => {
				this.locations.selected = cachedLocations.indexOf(loc.id) > -1;
			});
		}
		this.$emit('locationsLoaded', {
			locations: this.locations
		});
	},
	methods: {
		resetLocations(){
			this.locations.forEach(loc => {
				loc.selected = false;
			});
		},
		loadLocations: async function(){
			const result = await restClient.ajax({
				endpoint: 'location',
				method: 'GET',
				data: {
					pageSize: 1000,
				}
			});
			
			result.data.forEach(loc => {
				loc.selected = false;
				
				if(this.selectedLocations.indexOf(loc.id) > -1){
					loc.selected = true;
				}
				
				this.locations.push(loc);
				if(loc.parent){
					this.childLocations.push(loc);
				}else{
					this.mainLocations.push(loc);
				}
			});
			
			Cache.set('locations', {
				locations: JSON.parse(JSON.stringify(this.locations)),
				mainLocations: JSON.parse(JSON.stringify(this.mainLocations)),
				childLocations: JSON.parse(JSON.stringify(this.childLocations)),
			});
		},
		toggleOpen: function(){
			this.open = !this.open;
		},
		toggleLocation: function(location){
			location.selected = !location.selected;
			this.$emit('locationChanged', location);
		},
		searchLocation: function(){
			if(this.searchTimeout){
				clearTimeout(this.searchTimeout);
			}
			this.searchTimeout = setTimeout(() => {
				this.searchResults = [];
				if(this.searchTerm.length >= this.minSearchTermLength){
					let filters = [];
					this.searchTerm.split(' ').forEach(term => {
						filters.push(new RegExp('.*' + escapeRegExp(term) + '.*', 'i'));
					});
					this.locations.forEach(location => {
						let hit = false;
						for(let i = 0; !hit && i < filters.length; i++){
							hit = location.name.match(filters[i]);
						}
						
						if(hit){
							this.searchResults.push(location);
						}
					});
				}
			}, 300);
		}
	},
}
</script>

<style scoped>

</style>