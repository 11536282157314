<template>
	<nav class="nav-bar">
		<div class="nav-bar-content">
			<div class="nav-bar-search-filter">
				<div @click="toggleSearch" :class="{toggled: showSearch,}" class="clickable" role="button">
					<Icon name="search" title="Suchen"/>
				</div>
				<div @click="openFilter" :class="{toggled: filterOpen}" class="clickable" role="button">
					<Icon name="filter_alt" title="Filtern"/>
				</div>
			</div>
			<div class="nav-bar-search-result">
				<div>
					<div>
						Treffer: {{ searchCount }}
					</div>
				</div>
			</div>
			<div class="nav-bar-mode-switches">
				<div class="clickable" role="button" @click="toggleMap">
					<template v-if="mapOpen">
						<Icon name="view_list" title="Listenansicht"/>
					</template>
					<template v-else>
						<Icon name="location_on" title="Kartenansicht"/>
					</template>
				</div>
				<div class="clickable" :class="{toggled: helpOpen}" role="button" @click="toggleHelp">
					<i class="material-icons" style="font-weight: bold;font-family: inherit;">?</i>
				</div>
			</div>
		</div>
		<transition name="slide">
			<div v-if="showSearch">
				<div class="navbar-search-block">
					<input type="text" v-model="searchTerm" @keyup="checkStartSearch"/>
					<div class="nav-search-button clickable" @click="startSearch">
						Suchen
					</div>
					<div
						class="navbar-cancel-search clickable"
						@click="searchTerm = ''; startSearch()"
						role="button"
						title="Suchbegriff löschen"
					>
						<Icon name="close"/>
					</div>
				</div>
			</div>
		</transition>
	</nav>
</template>

<script>
import {Filter} from '../storage';

export default {
	name: "NavBar",
	data(){
		return {
			searchCount: 0,
			showSearch: false,
			removeSearch: true,
			searchTerm: '',
			filterOpen: false,
			mapOpen: false,
			helpOpen: false,
		};
	},
	mounted(){
		this.$root.$on('searchDone', data => {
			this.searchCount = data.eventCount;
		});
		this.$root.$on('changeMainView', evt => {
			if(evt.view === 'EventList'){
				this.filterOpen = false;
			}
			if(evt.view === 'SearchFilter'){
				this.filterOpen = true;
			}
			
			if(evt.nav){
				if(typeof evt.nav.search !== 'undefined'){
					this.showSearch = evt.nav.search;
				}
				if(typeof evt.nav.filter !== 'undefined'){
					this.filterOpen = evt.nav.filter;
				}
				if(typeof evt.nav.map !== 'undefined'){
					this.mapOpen = evt.nav.map;
				}
				if(typeof evt.nav.help !== 'undefined'){
					this.helpOpen = evt.nav.help;
					this.$root.$emit('closeHelp');
				}
			}
			
			
		});
		this.$root.$on('filterChanged', evt => {
			if(!Filter.has('searchTerm')){
				this.searchTerm = '';
			}
		});
		
		this.$root.$on('removePopup', (evt) => {
			if(evt.id === 'help'){
				this.helpOpen = false;
			}
		});
		
		if(Filter.has('searchTerm')){
			this.searchTerm = Filter.get('searchTerm');
			this.showSearch = true;
		}
	},
	methods: {
		openFilter(){
			if(!this.filterOpen){
				this.filterOpen = true;
				this.$root.$emit('changeMainView', {
					view: 'SearchFilter',
					nav: {
						map: false,
						help: false,
					}
				});
			}else{
				this.filterOpen = false;
				this.$root.$emit('changeMainView', {
					view: 'EventList',
					nav: {
						filter: false,
						map: false,
						help: false,
					},
				});
			}
		},
		toggleMap(){
			if(!this.mapOpen){
				this.mapOpen = true;
				this.$root.$emit('changeMainView', {view: 'MapFilter'});
			}else{
				this.mapOpen = false;
				this.$root.$emit('changeMainView', {view: 'EventList'});
			}
		},
		toggleSearch(){
			this.showSearch = !this.showSearch;
		},
		checkStartSearch(evt){
			if(evt.keyCode === 13){
				this.startSearch();
			}
		},
		startSearch(){
			if(this.searchTerm){
				Filter.set('searchTerm', this.searchTerm);
			}else{
				if(Filter.has('searchTerm')){
					Filter.delete('searchTerm');
				}
			}
			this.filterOpen = false;
			this.$root.$emit('changeMainView', {view: 'EventList'});
			this.$root.$emit('filterChanged', Filter);
		},
		toggleHelp(){
			if(!this.helpOpen){
				this.helpOpen = true;
				this.$root.$emit('addPopup', {
					id: 'help',
					cssClass: 'help-popup',
					component: 'Help',
				});
			}else{
				this.$root.$emit('closeHelp', {id: 'help', wait: 500});
			}
		},
	},
}
</script>

<style scoped>

</style>