<template>
	<div class="event-card">
		<div v-if="eventDate.canceled" class="event-card-canceled">
			<h2>Abgesagt</h2>
		</div>
		<div class="event-card-header">
			<div class="event-base-info">
				<h3>
					<a
						class="onclick-link"
						@click="showEvent"
						:href="`#!=${eventDate.id}`"
					>
						{{ title }}
					</a>
				</h3>
				<div class="date-location">
					<h3 class="date">
						<a
							class="onclick-link"
							@click="showEvent"
							:href="`#!=${eventDate.id}`"
						>
							{{ dateTime }}
						</a>
					</h3>
					<h3 class="location">
						<a
							class="onclick-link"
							@click="showEvent"
							:href="`#!=${eventDate.id}`"
						>
							{{ eventDate.location.name }}
						</a>
					</h3>
				</div>
			</div>
		</div>
		<div class="event-image-and-description">
			<div class="event-image">
				<a
					class="onclick-link"
					@click="showEvent"
					:href="`#!=${eventDate.id}`"
				>
					<img :src="mainImage.src" :alt="mainImage.name"/>
				</a>
			</div>
			<div class="event-description">
				<p v-html="description"></p>
			</div>
		</div>
		<div class="event-card-footer">
			<div class="event-card-footer-pictograms">
				<div v-if="eventDate.event.sustainability">
					<img :src="icon_url + '/eco_black' + icon_size + 'icon_white.png'" alt="Nachhaltig"/>
				</div>
				<div v-if="eventDate.event.accessible">
					<img :src="icon_url + '/accessible_black' + icon_size + 'icon_white.png'" alt="Barrierefrei"/>
				</div>
				<div v-if="eventDate.event.family_friendly">
					<img :src="icon_url + '/family_restroom' + icon_size + 'icon_white.png'" alt="Familienfreundlich"/>
				</div>
				<div v-if="eventDate.event.with_cost">
					<i class="material-icons">euro_symbol</i>
				</div>
			</div>
			<a
				class="event-card-footer-more onclick-link"
				@click="showEvent"
				role="button"
				title="Mehr lesen"
				:href="`#!=${eventDate.id}`"
			>
				<div>
					Mehr lesen
				</div>
			</a>
		</div>
	</div>
</template>

<script>
import {trimText} from '../../html';
import {formatDate, formatTime, getWeekdayShort} from '../../helper';
import {setQuery, parseQuery} from '../../url';
import {Config, Cache} from '../storage';

export default {
	name: "EventCard",
	data(){
		return {
			icon_size: '/2x/',
			icon_url: 'https://events.hassberge.de/storage/icons',
		};
	},
	props: {
		eventDate: {
			type: Object,
		}
	},
	computed: {
		mainImage(){
			if(this.eventDate.event.media.length > 0){
				for(let media of this.eventDate.event.media){
					if(media.kind === 'image'){
						let url;
						if(media.url.includes('events.hassberge.de')){
							url = media.url;
						}else{
							url = `https://events.hassberge.de${media.url}`;
						}
						return {
							src: url,
							name: media.name,
						};
					}
				}
			}
			
			return 'https://via.placeholder.com/100x80.png'
		},
		description(){
			if(this.eventDate.description !== null){
				return trimText(this.eventDate.description, 250, true);
			}
			
			return trimText(Config.get('descriptionFallback'), 500);
		},
		title(){
			let title = this.eventDate.event.name;
			if(this.eventDate.title){
				title = `${title} - ${this.eventDate.title}`;
			}
			
			return title;
		},
		weekdayStart(){
			return getWeekdayShort(this.eventDate.date_start);
		},
		weekdayEnd(){
			return getWeekdayShort(this.eventDate.date_end);
		},
		dateTime(){
			let dateTimeString = `${this.weekdayStart} ${formatDate(this.eventDate.date_start)}`;
			if(this.eventDate.date_end && this.eventDate.date_start !== this.eventDate.date_end){
				dateTimeString += ` - ${this.weekdayEnd} ${formatDate(this.eventDate.date_end)}`;
			}
			if(this.eventDate.time_start || this.eventDate.time_end){
				dateTimeString += ' |';
				if(this.eventDate.time_start && this.eventDate.time_end){
					dateTimeString += ` ${formatTime(this.eventDate.time_start)} - ${formatTime(this.eventDate.time_end)} Uhr`;
				}else{
					if(this.eventDate.time_start){
						dateTimeString += ` ab ${formatTime(this.eventDate.time_start)} Uhr`;
					}else{
						dateTimeString += ` bis ${formatTime(this.eventDate.time_end)} Uhr`;
					}
				}
			}
			
			return dateTimeString;
		},
	},
	methods: {
		showEvent(event){
			event.preventDefault();
			this.$root.$emit('removePopup', {id: 'eventPopup'});
			
			this.$root.$emit('addPopup', {
				id: 'eventPopup',
				component: 'EventPopup',
				params: {
					eventDate: JSON.parse(JSON.stringify(this.eventDate)),
				},
			});
			Cache.set('oldQuery', parseQuery());
			setQuery({event: this.eventDate.id}, Object.keys(Cache.get('oldQuery')));
		},
	},
}
</script>

<style scoped>

</style>